<!-- 
	Priklad vstupnich hodnot
	<div><span><span>A</span> <span>place</span> <span>to</span> <span>meet,</span></span></div>
	<div><span><span>share</span> <span>ideas</span></span></div>
	<div><span><span>and</span> <span>opportunities</span></span></div>
 -->

<script setup>
const startAnimation = ref(false);

const props = defineProps({
  title: {
    type: String,
  },
});

onMounted(() => {
  startAnimation.value = true;
});
</script>

<template>
  <h1 :class="{ animating: startAnimation }" v-html="title" />
</template>

<style lang="scss" scoped>
h1 {
  &.animating:deep(div) {
    width: 100%;

    @media (max-width: 1024px) {
      opacity: 1;
    }
		@media (min-width: 1024px) {
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: $i * 0.35s;
      }
    }
	}
  }

  &:deep(div) {
    
    
    overflow: hidden;
    line-height: 64px;

		@media (min-width: 1024px) {
			transition: 0.75s ease-in-out;
			width: 0;
		}

    @media (max-width: 1024px) {
      line-height: 52px;
      width: 100%;
      opacity: 1;
    }
  }
	&:deep(div span) {
    padding: 0;
    font-size: 48px;
    color: #ffffff;
    background: #1a1a1a;
    font-family: Roboto;
    font-weight: 300;
    display: inline-block;
    line-height: 64px;
    white-space: nowrap;

    @media (max-width: 1024px) {
      font-size: 40px;
			padding: 0;
      line-height: 52px;
      white-space: normal;
			position: relative;
			z-index: 2;

			&::before {
				height: 100%;
				width: 12.5px;
				background: #1a1a1a;
				position: absolute;
				z-index: 1;
				left: -11px;
				top: 0;
				content: '';
			}
			&::after {
				height: 100%;
				width: 12.5px;
				background: #1a1a1a;
				position: absolute;
				z-index: 1;
				right: -11px;
				top: 0;
				content: '';
			}
    }
  }
	&:deep(div > span) {
    padding: 0 16px;
    font-size: 48px;
    color: #ffffff;
    background: #1a1a1a;
    font-family: Roboto;
    font-weight: 300;
    display: inline-block;
    line-height: 64px;
    white-space: nowrap;

    @media (max-width: 1024px) {
			padding: 0 25px;
			white-space: normal;
			line-height: 46px;
			background: transparent;

			&::before {
				display: none;
			}
			&::after {
				display: none;
			}
    }
  }
  
}
</style>
